<template>
  <b-row>
    <b-col cols="12">
      <b-card>
        <b-button
          variant="primary"
          @click="print(check.no )"
        >
          print
        </b-button>
      </b-card>
    </b-col>
    <b-col class="text-center">
      <b-card>
        <div id="header">
          <img
            src="@/assets/images/logo/logo.jpg"
            alt=""
          >
          <label
            id="text"
            for=""
          >
            رخصة سير مؤقتة  &nbsp; <br> <br> رقم :{{ check.no }}</label>
        </div>
        <div id="body">
          <div class="right">
            <table
              class="betweenRows"
            >
              <tr>
                <td id="firstTd">
                  رقم لوحة التجربة :
                </td>
                <td id="secondTd">
                  {{ check.plate_data.number }}
                </td>
              </tr>
              <tr style="border-spacing:0!important;">
                <td id="firstTd">
                  المالك :
                </td>
                <td id="secondTd">
                  {{ check.owner_data.name }}
                </td>
              </tr>
              <tr>
                <td colspan="2">
                  <div
                    class="separator"
                  >
                    أوصاف المركبة :

                  </div>
                </td>

              </tr>
              <tr>
                <td id="firstTd">
                  رقم الشاصية
                </td>
                <td id="secondTd">
                  {{ check.checkin_data.chassis_number }}
                </td>
              </tr>
              <tr>
                <td id="firstTd">
                  ماركة المركبة :
                </td>
                <td id="secondTd">
                  {{ check.car_data.manufacturer }}
                </td>
              </tr>
              <tr>
                <td id="firstTd">
                  وزن المـركـبة :
                </td>
                <td id="secondTd">
                  {{ check.car_data.wight }}
                </td>
              </tr>
              <tr>
                <td id="firstTd">
                  اللـــــــــــــون :
                </td>
                <td id="secondTd">
                  {{ check.color }}
                </td>
              </tr>
              <tr>
                <td id="firstTd">
                  تاريـخ الإصدار :
                </td>
                <td id="secondTd">
                  {{ check.created_at.substring(0, 10) }}
                </td>
              </tr>

              <tr>
                <td id="firstTd">
                  نظامية / تجميع :
                </td>
                <td id="secondTd">
                  {{ $t(check.checkin_data.type) }}
                </td>
              </tr>
            </table>
          </div>
          <div class="vertical" />
          <div class="left">
            <table class="betweenRows">
              <tr>
                <td id="firstTd">
                  الرقم التسلسلي :
                </td>
                <td id="secondTd">
                  {{ check.no }}
                </td>
              </tr>
              <tr>
                <td id="firstTd">
                  هوية المالك :
                </td>
                <td id="secondTd">
                  {{ $t(check.owner_data.identification_type) }} / {{ check.owner_data.identify }}
                </td>
              </tr>
              <tr>
                <td colspan="2">
                  <div>
                                    &nbsp;

                  </div>
                </td>

              </tr>
              <tr>
                <td id="firstTd">
                  سمة الرقم :
                </td>
                <td id="secondTd">
                  {{ check.plate_data.number }}
                </td>
              </tr>
              <tr>
                <td id="firstTd">
                  طــراز المركبة :
                </td>
                <td id="secondTd">
                  {{ check.car_data.model }}
                </td>
              </tr>
              <tr>
                <td id="firstTd">
                  رقم العداد :
                </td>
                <td id="secondTd">
                  {{ check.km }}
                </td>
              </tr>
              <tr>
                <td id="firstTd">
                  سنـــة الصنـــع :
                </td>
                <td id="secondTd">
                  {{ check.checkin_data.created_year }}
                </td>
              </tr>
              <tr>
                <td id="firstTd">
                  تاريــخ الانتهاء :
                </td>
                <td id="secondTd">
                  {{ check.expiration_date }}
                </td>
              </tr>

              <tr>
                <td id="firstTd">
                  عام الاستيراد :
                </td>
                <td id="secondTd">
                  {{ check.created_at.substring(0, 4) }}
                </td>
              </tr>
              <tr>
                <td
                  id="secondTd2"
                  colspan="2"
                  style="text-align: end"
                >

                  رئيس القسم <label for="">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</label><br> المهندس أحمد الصالح

                  <label for="">&nbsp;&nbsp;&nbsp;</label>
                </td>

              </tr>
            </table>
          </div>
        </div>
      </b-card>

      <b-card
        id="printMe"
        style="visibility: hidden;"
      >
        <img
          src="@/assets/images/card/front2.jpeg"
          alt=""
          :style="{'height':'100%','width':'100%' ,'position': 'absolute',
                   'top':'0','bottom':'0','left':'0','right':'0'}"
        >
        <h5
          style="position: fixed;
          top: -8px;
          font-family: Almarai;
          left: 2px;"
        >{{ check.no }}</h5>
        <h3
          style="position: fixed;
                            top: 23px;
                            font-family: Almarai;
                            left: 5px;"
        >{{ check.plate_data.number }}</h3>
        <h6
          style="position: fixed;
                            top: 60px;
                            font-family: Almarai;
                            left: 5px;"
        >{{ $t(check.car_data.car_type) }} / خاصة</h6>
        <h6
          style="position: fixed;
                            top: 89px;
                            font-family: Almarai;
                            left: 5px;"
        >{{ $t(check.owner_data.name) }}</h6>
        <h6
          style="position: fixed;
                            top:123px;
                            font-family: Almarai;
                            left: 5px;"
        >{{ check.owner_data.identify }}</h6>
        <h3
          style="position: fixed;
                            top: 159px;
                            font-family: Almarai;
                            left: 5px;"
        >{{ check.expiration_date }} / {{ check.created_at }}</h3>
        <img
          src="@/assets/images/card/back2.jpeg"
          alt=""
          :style="{'height':'100%','width':'100%' ,'position': 'absolute',
                   'top':'100%','bottom':'200%','left':'0','right':'0'}"
        >
        <h6
          style="position: fixed;
                    top: 98%;
                    font-family: Almarai;
                    font-size: 9px!important;
                    left: 2px;"
        >{{ check.car_data.model }}</h6>
        <h6
          style="position: fixed;
                    top: 95%;
                    font-family: Almarai;
                    left: 182px;"
        >{{ check.car_data.manufacturer }}</h6>
        <h5
          style="position: fixed;
                    top: 115%;
                    font-family: Almarai;
                    left: 5px;"
        >{{ check.color }}</h5>
        <h5
          style="position: fixed;
                    top: 115%;
                    font-family: Almarai;
                    left: 192px;"
        >{{ check.checkin_data.created_year }}</h5>
        <h5
          style="position: fixed;
                    top: 134%;
                    font-family: Almarai;
                    left: 15px;"
        >{{ check.car_data.wight1 }}</h5>
        <h5
          style="position: fixed;
                    top: 134%;
                    font-family: Almarai;
                    left: 192px;"
        >{{ check.car_data.wight }}</h5>
        <h4
          style="position: fixed;
                    top: 156%;
                    color: white!important;
                    filter:  brightness(0) invert(1);
                    font-weight: bolder;
                    left: 65px;"
        >{{ check.checkin_data.chassis_number }}</h4>
        <h6
          style="position: fixed;
                    top: 175%;
                    font-family: Almarai;
                    color: white!important;
                    filter:  brightness(0) invert(1);
                    left: 10px;"
        >{{ check.car_data.passenger }}</h6>
        <h6
          style="position: fixed;
                    top: 175%;
                    font-family: Almarai;
                    color: white!important;
                    filter:  brightness(0) invert(1);
                    left: 135px;"
        >{{ $t(check.checkin_data.type) }}</h6>
      </b-card>

    </b-col>
  </b-row>
</template>
<script>
export default {
  data() {
    return {}
  },
  computed: {
    check() {
      return this.$store.getters['check/GetElement']
    },
  },
  created() {
    this.$store.dispatch('check/GetElement', this.$route.params.check)
  },
  methods: {
    async print(itemId) {
      const options = {
        name: '_blank',
        specs: [
          'fullscreen=yes',
          'titlebar=yes',
          'scrollbars=yes',
        ],
        styles: [
          '../../../../assets/scss/certificate.css',
        ],
        timeout: 10, // default timeout before the print window appears
        autoClose: true, // if false, the window will not close after printing
        windowTitle: itemId, // override the window title
        title: itemId,
      }
      // Pass the element id here
      // await this.$htmlToPaper('printMe', { options })
      await this.$htmlToPaper('printMe', { options, styles: ['/style.css'] })
    },
  },
}</script>
<style>
        .separator {
            color: black;
            font-size: 14px;
            font-weight: bold;
            font-family:"Traditional Arabic";
            display: flex;
            align-items: center;
            text-align: center;
            content: '';
            flex: 1;

        }

        .separator::before,
        .separator::after {
            content: '';
            flex: 1;
            border-bottom: 1px solid #000;
        }

        .separator:not(:empty)::before {
            margin-right: 10px;
        }

        .separator:not(:empty)::after {
            margin-left: 10px;
        }
        table {
            border-collapse: collapse;
        }
        .betweenRows {
            border-collapse:separate;
            border-spacing:0 10px;
        }
        #specific{
            height: 5px!important;
            border-collapse:separate;
            border-spacing:0!important;
            background-color: #1f9d57;
            margin-top: 0px;
        }
        #firstTd{
            width: 100px;
            background-color: #00a1b5;
            alignment: right;
            border: #000 solid 2px;
            height: 35px;
            font-family: "Traditional Arabic";
            font-weight: bold;
            font-size: 14px;
            color: black;
        }
        #secondTd{
            background-color: #fff;
            width: 190px;
            border: #000 solid 2px;
            height: 35px;
            font-family: "Traditional Arabic";
            font-weight: bold;
            font-size: 14px;

            color: black;
        }
        #secondTd2{
            width: 190px;
            height: 35px;
            font-family: "Traditional Arabic";
            font-weight: bold;
            font-size: 14px;

            color: black;
        }
        #body{
            background-color: white;
            width: 600px;
            height: 450px;
        }
        .vertical {
            border-left: 4px dashed black;
            height: 400px;
            position:absolute;
            left: 320px;
        }
        .left {
            position:absolute;
            left: 330px;
        }
        .right {

            position:absolute;
        }
        #header{
            background-color: white;
            width: 600px;
            height: 71px;
            text-align: end;
        }
        #text{
            font-family: Arial;
            text-space: 10px;
            color: red;
            font-size: 14px;
            font-weight: bold;
            text-align: center;
            margin-top:10px ;
            margin-bottom:10px!important;
            padding-bottom:10px!important;
        }
        img{
            margin-right: 380px!important;
            margin-bottom: 50px;
        }
</style>
